import React from 'react'
import CircleImg from '../images/svg/main-circle.svg'
import styles from './block-list.module.scss'

export default ({txt,circleimg}) => {
	const hasCircle = circleimg

	return(
		<section className={styles.blocklist}>
			<div className={`${styles.blocklistContent} container`}>
			{hasCircle ? <CircleImg className={styles.circleimg}/> : ''}
			<h2 className={styles.blocklistContentTitle}>{txt.title}</h2>
				<div className={styles.blocklistContentList}>
					<ul>
						{txt.items.map((item, index)=>{
							return <li className={styles.blocklistContentListItem} key={ index }>{item}</li>;
						})}
					</ul>
				</div>
			</div>
		</section>
	)
}