import React from 'react'
import { Helmet } from 'react-helmet'

import HeroPage from '../components/hero-page'
import Layout from '../components/layout'
import BlockList from '../components/block-list'

class Informatique extends React.Component {
  render() {
    const siteTitle = 'L\'Atelier Info | Nos services informatique aux entreprises et aux particuliers'
    const siteDesc = 'Nous offrons des services informatique pour les entreprises et les particuliers, réparations, ventes, réseaux, installations, configurations ...'
    const titlePage = 'Nos services informatique aux entreprises et aux particuliers'
    const content1 = {
      title:'Services de support informatique aux Particuliers',
      items:[
        'Diagnostique',
        'Migration Windows',
        'Réseautique et optimisation Wifi ',
        'Sauvegarde et récupération de données',
        'Installation et configuration de logiciels',
        'Réparation d’ordinateurs',
        'Assistance à distance',
        'Assistance en magasin',
        'Installation et configuration des outils de télétravail',
        'Gestion des configurations des courriels',
        'Formation sur les outils Office en collaboration avec Formations Qualitemps'
      ]
    }
    const content2 = {
      title:'Services de support informatique aux entreprises',
      items:[
        'Sauvegarde, transfert et récupération de données',
        'Optimisation d’ordinateur, nettoyage et désinfection de virus',
        'Installation de périphériques',
        'Mise en place du Télétravail et système de gestion des employés à distance',
        'Mise en place de réseaux virtuels privés (VPN) ',
        'Formation sur les outils Office en collaboration avec Formations Qualitemps',
        'Installation de serveurs',
        'Maintenance de serveurs',
        'Gestion et configuration des courriels',
        'Configuration de magasins en ligne',
        'Migration Office 365',
        'Migration Windows',
        'Support Technique des Progiciels de Gestion Intégré',
        'Support Technique des systèmes comptables',
        'Support Technique des Points de Ventes, POS.',
        'Sécurité informatique',
        'Ajout de câbles réseaux ',
        'Copies de sauvegardes périodiques en ligne',
        'Installation et configuration de logiciels',
        'Configuration de solutions infonuagique (cloud)'
      ]
    }
    const content3 = {
      title:'Services de directeur technique à temps partiel pour les entreprises',
      items:[
        'Alignement stratégique des technologies de l\'information avec les objectifs d’affaires de l’organisation ',
        'Services conseils technologies de l\'information',
        'Gestions de projets technologies de l\'information ',
        'Implantation de systèmes de points de ventes POS, Progiciels de Gestion Intégré et comptables ',
        'Planification de déploiements'
      ]
    }
    

    return (
      <Layout location={this.props.location}>
        <div className="page-wrapper">
        <Helmet title={siteTitle}>
            <meta name="description" content={siteDesc} />
        </Helmet>
          <HeroPage title={titlePage} imgpage="informatique" hasBtn='Soutien à distance' btnHref="https://get.teamviewer.com/6fik5vy"/>
          <BlockList txt={content1} circleimg={true}/>
          <BlockList txt={content2} />
          <BlockList txt={content3} />
        </div>
      </Layout>
    )
  }
}

export default Informatique

