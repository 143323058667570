import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Line from '../images/svg/line-hero.svg'

import styles from './hero-page.module.scss'

export default ({title,imgpage, hasBtn, btnHref}) => {
  return (
    <StaticQuery
    query={graphql`
      query {
        informatique: file(relativePath: { eq: "informatique/info-repair.png" }) {
          childImageSharp {
            fluid(maxWidth: 750, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobiles: file(relativePath: { eq: "mobiles/phone-repair.png" }) {
          childImageSharp {
            fluid(maxWidth: 750, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tarifs: file(relativePath: { eq: "tarifs/smartphone-repair.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 750, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <section className={`${styles.heropage} container`}>
        <Line className={styles.heropageImgbg}/>
        <div className={styles.heropageImgillusWrapper}>
          <Img className={styles.heropageImgillusItem} fluid={[data[imgpage].childImageSharp.fluid]}/>
        </div>
        <div className={styles.heropageDetails}>
        <h1 className={styles.heropageDetailsTitle}>{title}</h1>
        {hasBtn ? <a href={btnHref} className="btn btn--primary">{hasBtn}</a> : ''}
        </div>
      </section>
    )}
    />
  )
}